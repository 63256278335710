
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Inicio from './inicio';
import  About  from './aboutUs';
import './App.css';
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import RegistroForm from "./registro";

function App() {
  return (  
    <BrowserRouter>
      <div className="App">
        <nav className="navbar">
          <ul className="nav-menu">
            <li className="nav-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="nav-item">
              <Link to="/registro">Registro</Link>
            </li>
            <li className="nav-item">
              <Link to="/aboutUs">About Us</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/registro" element={<RegistroForm />} />
          <Route path='/aboutUs' element={<About/>}/>
        </Routes>
      </div>
    </BrowserRouter>
   

  );
}

export default App;



{/*import './App.css';
import { useState } from "react";
import Axios from "axios";
import { Link, Route, Routes, BrowserRouter } from "react-router-dom";

function App() {
  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [web, setWeb] = useState("");
  const [producto, setProducto] = useState("");
  const [certificacion, setCertificacion] = useState("");

  const addProveedor = () => {
    Axios.post("http://localhost:3001/create", {
      nombre: nombre,
      direccion: direccion,
      telefono: telefono,
      correo: correo,
      web: web,
      producto: producto,
      certificacion: certificacion
    }).then(() => {
      alert("Proveedor registrado");
    });
  }

  return (
    <BrowserRouter>
      <div className="App">
        <nav className="navbar">
          <ul className="nav-menu">
            <li className="nav-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="nav-item">
              <Link to="/registro">Registro</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/registro" element={<RegistroForm addProveedor={addProveedor} />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

function RegistroForm({ addProveedor }) {
  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [web, setWeb] = useState("");
  const [producto, setProducto] = useState("");
  const [certificacion, setCertificacion] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    addProveedor();
  };

  return (
    <div className="form">
      <h1>Hazte proveedor</h1>
      <form onSubmit={handleSubmit}>
        <label>Nombre completo o nombre de la empresa:
          <input
            onChange={(event) => {
              setNombre(event.target.value);
            }}
            type="text"
            required
          />
        </label><br />

        <label>Dirección:
          <input
            onChange={(event) => {
              setDireccion(event.target.value);
            }}
            type="text"
            required
          />
        </label><br />

        <label>Número de teléfono:
          <input
            onChange={(event) => {
              setTelefono(event.target.value);
            }}
            type="text"
            required
          />
        </label><br />

        <label>Correo electrónico:
          <input
            onChange={(event) => {
              setCorreo(event.target.value);
            }}
            type="email"
            required
          />
        </label><br />

        <label>Sitio web:
          <input
            onChange={(event) => {
              setWeb(event.target.value);
            }}
            type="text"
          />
        </label><br />

        <label>Categoría de productos o servicios:
          <input
            onChange={(event) => {
              setProducto(event.target.value);
            }}
            type="text"
            required
          />
        </label><br />

        <label>Certificaciones o acreditaciones:
          <input
            onChange={(event) => {
              setCertificacion(event.target.value);
            }}
            type="file"
          />
        </label><br />

        <label>
          Términos y condiciones:
          <input type="checkbox" required /> Acepto los términos y condiciones
        </label><br />
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default App;

*/}






{/*
import './App.css';
import { useState } from "react";
import Axios from "axios";
import { Link, Route, Routes, BrowserRouter } from "react-router-dom";



function App() {

  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [web, setWeb] = useState("");
  const [producto, setProducto] = useState("");
  const [certificacion, setCertificacion] = useState("");

  const addProveedor = () => {

    Axios.post("http://localhost:3001/create", {
      nombre: nombre,
      direccion: direccion,
      telefono: telefono,
      correo: correo,
      web: web,
      producto: producto,
      certificacion: certificacion
    }).then(() => {
      alert("Proveedor registrado")
    });
  }

  return (
    <BrowserRouter>
    <Routes>
      <div className="App">
        <nav className="navbar">
          <ul className="nav-menu">
            <li className="nav-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="nav-item">
              <Link to="/registro">Registro</Link>
            </li>
          </ul>
        </nav>
          <Route path="/registro">
            <div className="form">
              <h1>Hazte proveedor</h1>
              <label>Nombre completo o nombre de la empresa:
                <input
                  onChange={(event) => {
                    setNombre(event.target.value);
                  }}
                  type="text"
                  required
                />
              </label><br />

              <label>Dirección:
                <input
                  onChange={(event) => {
                    setDireccion(event.target.value);
                  }}
                  type="text"
                  required
                />
              </label><br />

              <label>Número de teléfono:
                <input
                  onChange={(event) => {
                    setTelefono(event.target.value);
                  }}
                  type="text"
                  required
                />
              </label><br />

              <label>Correo electrónico:
                <input
                  onChange={(event) => {
                    setCorreo(event.target.value);
                  }}
                  type="email"
                  required
                />
              </label><br />

              <label>Sitio web:
                <input
                  onChange={(event) => {
                    setWeb(event.target.value);
                  }}
                  type="text"
                />
              </label><br />

              <label>Categoría de productos o servicios:
                <input
                  onChange={(event) => {
                    setProducto(event.target.value);
                  }}
                  type="text"
                  required
                />
              </label><br />

              <label>Certificaciones o acreditaciones:
                <input
                  onChange={(event) => {
                    setCertificacion(event.target.value);
                  }}
                  type="file"
                />
              </label><br />

              <label>
                Términos y condiciones:
                <input type="checkbox" required /> Acepto los términos y condiciones
              </label><br />
              <button onClick={addProveedor}>Enviar</button>
            </div>

          </Route>
        
      </div>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
*/}