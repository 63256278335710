import { useState } from "react";
import Axios from "axios";
import './registro.css';

function RegistroForm() {
  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [web, setWeb] = useState("");
  const [producto, setProducto] = useState("");
  const [certificacion, setCertificacion] = useState("");

  const addProveedor = () => {
    Axios.post("http://localhost:3001/create", {
      nombre: nombre,
      direccion: direccion,
      telefono: telefono,
      correo: correo,
      web: web,
      producto: producto,
      certificacion: certificacion
    }).then(() => {
      alert("Proveedor registrado");
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    addProveedor();
  };

  return (
    <div className="form">
      <div class="card text-center">

        <div className="card-header">
          Házte proveedor
        </div>
        <div className="card-body">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Nombre completo o nombre de la empresa:</span>
            <input type="text"
              onChange={(event) => {
                setNombre(event.target.value);
              }}
              className="form-control" placeholder="Introduzca el nombre de su empresa" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Dirección:</span>
            <input type="text"
              onChange={(event) => {
                setDireccion(event.target.value);
              }}
              className="form-control" placeholder="Introduzca su dirección" aria-label="Direccion" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Teléfono:</span>
            <input type="text"
              onChange={(event) => {
                setTelefono(event.target.value);
              }}
              className="form-control" placeholder="Ej.: +42 788 231 987" aria-label="Telefono" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Correo electrónico:</span>
            <input type="text"
              onChange={(event) => {
                setCorreo(event.target.value);
              }}
              className="form-control" placeholder="Ej.: ejemplo@ejemplo.com" aria-label="Correo" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Web:</span>
            <input type="text"
              onChange={(event) => {
                setWeb(event.target.value);
              }}
              className="form-control" placeholder="Ej.: https://www.entreps.org/" aria-label="web" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Categoría de productos o servicios:</span>
            <input type="text"
              onChange={(event) => {
                setProducto(event.target.value);
              }}
              className="form-control" placeholder="Introduzca el productos" aria-label="Producto" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">Certificaciones o acreditaciones:</span>
            <input type="file"
              onChange={(event) => {
                setCertificacion(event.target.value);
              }}
              className="form-control" aria-label="Certificado" aria-describedby="basic-addon1" />
          </div>
          <br />
          <input type="checkbox" required />Acepto los términos y condiciones
          <br />
          <br />
          <div class="card-footer text-body-secondary">
            <button onClick={addProveedor}>Enviar</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default RegistroForm;
