import React from 'react';
import './aboutUs.css';

function About() {
  return (
    <div className="about-container">
      <h1>Información de la empresa</h1>
      <p>nombre: "Natural de Costa Rica"</p>
      <p>direccion: "Calle Principal, San José, Costa Rica"</p>
      <p>telefono: "+506 1234 5678"</p>
      <p>correo: "info@naturaldecostarica.com"</p>
      <p>sitioWeb: "www.naturaldecostarica.com"</p>
      <p>redesSociales:</p>
      <ul>
        <li>facebook: <a href="www.facebook.com/naturaldecostarica">www.facebook.com/naturaldecostarica</a></li>
        <li>instagram: <a href="www.instagram.com/naturaldecostarica">www.instagram.com/naturaldecostarica</a></li>
      </ul>
      <p>horario: "Lunes a viernes de 9:00 a.m. a 5:00 p.m."</p>
    </div>
  );
}

export default About;
