import React from 'react';
import './inicio.css';

function Inicio() {
  return (
    <div className="Inicio">
     {/* <h1>Vive Costa Rica</h1>*/}
     <br/>
      <iframe
        width="560"
        height="300"
        src="https://www.youtube.com/embed/LXb3EKWsInQ"
        title="Video de YouTube"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Inicio;
